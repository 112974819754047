export const navItems = [
  {
    label: "Home",
    elementId: "who-i-am",
  },
  {
    label: "Stack",
    elementId: "what-i-know",
  },
  {
    label: "Projects",
    elementId: "my-projects",
  },
  // {
  //   label: "About",
  //   elementId: "why-hire-me",
  // },
];
